import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const chartApiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: ['Chart', 'CodingResult', 'ICDCode'],
    endpoints: (builder) => ({
        uploadChart: builder.mutation({
            query: (formData) => ({
                url: 'upload',
                method: 'POST',
                body: formData,
            }),
        }),
        uploadText: builder.mutation({
            query: (formData) => ({
                url: 'upload_text',
                method: 'POST',
                body: formData,
            }),
        }),
        fetchCharts: builder.query({
            query: ({ page = 1, pageSize = 25, status = 'new', chartSource = null }) =>
                `charts/?page=${page}&page_size=${pageSize}&status=${status}&chartSource=${chartSource}`,
            transformResponse: (response) => ({
                data: response.results,
                total: response.count,
            }),
            providesTags: (result, error, { page, pageSize, status }) =>
                result
                    ? [
                          ...result.data.map(({ id }) => ({ type: 'Chart', id })),
                          { type: 'Chart', id: 'PARTIAL-LIST' },
                      ]
                    : [{ type: 'Chart', id: 'PARTIAL-LIST' }],
        }),
        deleteChart: builder.mutation({
            query: (id) => ({
                url: `charts/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Chart', id: 'PARTIAL-LIST' }],
        }),
        fetchChartDetails: builder.query({
            query: (id) => `charts/${id}/`,
            providesTags: (result, error, id) => [{ type: 'Chart', id }],
        }),
        fetchSlowChartData: builder.query({
            query: (id) => `charts/${id}/slow_data/`,
            providesTags: (result, error, id) => [{ type: 'Chart', id }],
        }),
        fetchChartCounts: builder.query({
            query: (chartSource) => `chart-counts/?chartSource=${chartSource}`,
            transformResponse: (response) => ({
                newCount: response.new_count,
                completedCount: response.completed_count,
                blockedCount: response.blocked_count,
                deficientCount: response.deficient_count,
            }),
        }),
        updateChart: builder.mutation({
            query: ({ id, ...patchData }) => ({
                url: `charts/${id}/`,
                method: 'PATCH',
                body: patchData,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Chart', id }],
        }),

        fetchCodingResults: builder.query({
            query: (chartId) => `coding-results/?chart=${chartId}`,
            providesTags: (result, error, chartId) => [{ type: 'CodingResult', id: chartId }],
        }),
        updateCodingResults: builder.mutation({
            query: ({ id, confirmed_codes, processing_time }) => ({
                url: `coding-results/${id}/`,
                method: 'PATCH',
                body: { confirmed_codes, processing_time },
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'CodingResult', id }],
        }),
        fetchUser: builder.query({
            query: () => 'user/',
        }),
        fetchChartSources: builder.query({
    query: () => 'chart-sources/',
    transformResponse: (response) => response.sources,
}),
      fetchIcdCodes: builder.query({
            query: (search) => `icd-codes/?search=${search}`,
            providesTags: (result) => {
                if (Array.isArray(result)) {
                    return [
                        ...result.map(({ code }) => ({ type: 'ICDCode', code })),
                        { type: 'ICDCode', id: 'LIST' },
                    ];
                }
                return [{ type: 'ICDCode', id: 'LIST' }];
            },
        }),
    }),
});

export const {
    useUploadChartMutation,
    useUploadTextMutation,
    useFetchChartsQuery,
    useDeleteChartMutation,
    useFetchChartDetailsQuery,
    useFetchSlowChartDataQuery,
    useFetchChartCountsQuery,
    useFetchCodingResultsQuery,
    useUpdateCodingResultsMutation,
    useFetchUserQuery,
    useFetchIcdCodesQuery,
    useUpdateChartMutation,
    useFetchChartSourcesQuery
} = chartApiSlice;
