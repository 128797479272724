 import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useFetchChartSourcesQuery } from '../apis/chart';

const ChartSourceSelector = ({ onChartSourceChange }) => {
    const { data: sources, isLoading } = useFetchChartSourcesQuery();
    const [chartSource, setChartSource] = useState('All');

    // Load the chart source from local storage when the component mounts
    useEffect(() => {
        const savedChartSource = localStorage.getItem('selectedChartSource');
        if (savedChartSource) {
            setChartSource(savedChartSource);
            onChartSourceChange(savedChartSource);
        }
    }, [onChartSourceChange]);

    const handleChange = (value) => {
        setChartSource(value);
        localStorage.setItem('selectedChartSource', value);
        onChartSourceChange(value);
    };

    if (isLoading) return null;

    return (
        <FormControl variant="outlined" sx={{ minWidth: 200, mr: 2 }}>
            <InputLabel id="chart-source-label">Chart Source</InputLabel>
            <Select
                labelId="chart-source-label"
                value={chartSource || 'All'}
                onChange={(e) => handleChange(e.target.value === 'All' ? 'All' : e.target.value)}
                label="Chart Source"
            >
                <MenuItem value="All">All</MenuItem>
                {sources.map((source) => (
                    <MenuItem key={source} value={source}>
                        {source}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ChartSourceSelector;
