import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import theme from '../../styles/theme';

const getCodeCardStyle = (code, aiIdentifiedConditions = [], userSelectedCodes = []) => {
    const isAIIdentified = aiIdentifiedConditions?.some(aiCondition =>
        aiCondition.codes && aiCondition.codes.some(aiCode => aiCode.icd_code.code === code.code)
    );

    // If not found in AI-identified conditions, consider it user-selected
    const isUserSelected = !isAIIdentified || userSelectedCodes.some(userCode => userCode.icd_code.code === code.code);

    if (isUserSelected) {
        return {
            backgroundColor: theme.palette.codes.user.background,
            color: theme.palette.codes.user.color,
        };
    } else if (isAIIdentified) {
        return {
            backgroundColor: theme.palette.codes.ai.background,
            color: theme.palette.codes.ai.color,
        };
    } else {
        return {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        };
    }
};


const CodeCard = ({ code, action, chevronLeft = false, aiIdentifiedConditions = [], userSelectedCodes = [] }) => (
    <Card key={code.icd_code.code} sx={{ marginTop: 0, marginBottom: 0, padding: 0, display: 'flex' }}>
        <Box sx={{ width: 8, ...getCodeCardStyle(code.icd_code, aiIdentifiedConditions, userSelectedCodes) }} />
        <Box onClick={() => action(code)} sx={{ flex: 1, cursor: 'pointer', display: 'flex', alignItems: 'center', padding: 1 }}>
            {chevronLeft && (
                <ChevronLeftIcon />
            )}
            <Box sx={{ flex: 1, marginLeft: chevronLeft ? 2 : 0 }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {code.icd_code.code}
                </Typography>
                <Typography variant="body2">
                    {code.icd_code.description}
                </Typography>
            </Box>
            {!chevronLeft && (
                <ChevronRightIcon />
            )}
        </Box>
    </Card>
);

export default CodeCard;
