import React, { useState } from 'react';
import TabbedCharts from './TabbedCharts';
import ChartSourceSelector from "./ChartSourceSelector";
import {Box} from "@mui/material";

const ChartTable = () => {
    const [chartSource, setChartSource] = useState("All");

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, paddingTop: '10px' }}>
                <ChartSourceSelector chartSource={chartSource} onChartSourceChange={setChartSource} />
            </Box>

            <TabbedCharts chartSource={chartSource}/>
        </div>
    );
};

export default ChartTable;
