import React, {useState, useEffect} from 'react';
import {
    Alert, Box, Typography, Grid, Snackbar, Paper
} from '@mui/material';
import {useUpdateCodingResultsMutation, useUpdateChartMutation} from '../../apis/chart';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import theme from '../../styles/theme';
import ConditionsList from './ConditionsList';
import ICDCodeSearch from './ICDCodeSearch';
import ProcessingTimeModal from './ProcessingTimeModal';
import FinalizedCodes from './FinalizedCodes';

const CompletedCodesBuilder = ({chart, onRefetch, highlightedText, handleCodeClick}) => {
    const codingResult = chart.coding_results || {};
    const aiIdentifiedConditions = codingResult.ai_identified_conditions || [];

    const [completedCodes, setCompletedCodes] = useState([]);
    const [userSelectedCodes, setUserSelectedCodes] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [updateCodingResults] = useUpdateCodingResultsMutation();
    const [updateChart] = useUpdateChartMutation();

    const handleAddCode = (code) => {
        setUserSelectedCodes((prev) => [...prev, {
            icd_code: {
                code: code.code,
                description: code.description,
                long_description: code.long_description
            },
            user_added: true
        }]);
    };

    useEffect(() => {
        if (codingResult.confirmed_codes) {
            const formattedCompletedCodes = codingResult.confirmed_codes.map(code => ({
                icd_code: {
                    code: code.code,
                    description: code.description,
                    long_description: code.long_description
                },
                is_recommended: false  // Assuming completed codes are not marked as recommended by default
            }));
            setCompletedCodes(formattedCompletedCodes);
        }
    }, [codingResult.confirmed_codes]);

    const handleMoveToCompleted = (code) => {
        if (!chart.is_processed) {
            setCompletedCodes((prev) => [...prev, code]);
        } else {
            setAlertOpen(true);
        }
    };

    const handleRemoveFromCompleted = (code) => {
        if (!chart.is_processed) {
            setCompletedCodes((prev) => prev.filter(item => item.icd_code.code !== code.icd_code.code));
        } else {
            setAlertOpen(true);
        }
    };

    const handleFinalizeClick = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalSubmit = async (time) => {
        try {
            await updateCodingResults({
                id: codingResult.uuid,
                confirmed_codes: completedCodes,
                processing_time: time,
            });
            onRefetch();
        } catch (error) {
            console.error('Failed to finalize codes:', error);
        }
    };

    const handleUnlockCodes = async () => {
        try {
            await updateChart({
                id: chart.uuid,
                is_processed: false,
            });
            onRefetch();
        } catch (error) {
            console.error('Failed to unlock codes:', error);
        }
    };

    const getHighlightedContent = () => {
        const textToHighlight = highlightedText;
        if (!textToHighlight || !chart?.content) return chart?.content;

        const parts = chart.content.split(new RegExp(`(${textToHighlight})`, 'i'));
        return parts.map((part, index) =>
            part.toLowerCase() === textToHighlight.toLowerCase() ? (
                <span key={index} style={{backgroundColor: theme.palette.highlight.main}}>{part}</span>
            ) : (
                part
            )
        );
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedCodes = Array.from(completedCodes);
        const [removed] = reorderedCodes.splice(result.source.index, 1);
        reorderedCodes.splice(result.destination.index, 0, removed);
        setCompletedCodes(reorderedCodes);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{padding: 2, border: '1px solid #ccc', borderRadius: 2, height: '100%', overflow: 'hidden'}}>
                <ICDCodeSearch onAddCode={handleAddCode}
                               aiIdentifiedCodes={aiIdentifiedConditions.flatMap(condition => condition.codes)}/>

                <Grid container spacing={2} sx={{height: 'calc(100% - 80px)'}}>
                    <Grid item xs={12} md={6} sx={{height: '100%', overflowY: 'auto'}}>
                        <Typography variant="h6" sx={{marginLeft: 2, marginBottom: 1}}>Suggested Codes</Typography>
                        <Box sx={{padding: 2, border: '1px solid #ccc', backgroundColor: '#f9f9f9'}}>
                            <ConditionsList
                                aiIdentifiedConditions={aiIdentifiedConditions}
                                completedCodes={completedCodes}
                                userSelectedCodes={userSelectedCodes}
                                handleMoveToCompleted={handleMoveToCompleted}
                                handleCodeClick={handleCodeClick}
                                highlightedText={highlightedText}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{height: '100%', overflowY: 'auto'}}>
                        <FinalizedCodes
                            completedCodes={completedCodes}
                            handleRemoveFromCompleted={handleRemoveFromCompleted}
                            aiIdentifiedConditions={aiIdentifiedConditions}
                            userSelectedCodes={userSelectedCodes}
                            onDragEnd={onDragEnd}
                            handleUnlockCodes={handleUnlockCodes}
                            handleFinalizeClick={handleFinalizeClick}
                            chart={chart}
                        />
                    </Grid>
                </Grid>
                <Box sx={{marginTop: 4, height: '20%'}}>
                    <Typography variant="h6">Chart Content</Typography>
                    <Paper elevation={3} sx={{padding: 3, borderRadius: 2, height: '100%', overflowY: 'auto'}}>
                        <Typography variant="body1" component="div">
                            {getHighlightedContent()}
                        </Typography>
                    </Paper>
                </Box>
                {highlightedText && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            width: '400px',
                            padding: 3,
                            backgroundColor: 'white',
                            boxShadow: 3,
                            zIndex: 1000,
                            pointerEvents: 'none'
                        }}
                    >
                        <Typography variant="body" sx={{fontStyle: 'italic', fontWeight: 'bold'}} color="textSecondary">
                            Source:
                        </Typography>
                        <br/>
                        <Typography variant="body"
                                    sx={{display: 'inline', backgroundColor: theme.palette.highlight.main}}>
                            {highlightedText}
                        </Typography>
                        <br/><br/>
                        <Typography variant="body" sx={{fontStyle: 'italic', fontWeight: 'bold'}} color="textSecondary">
                            Rationale:
                        </Typography>
                        <br/>
                        <Typography variant="body">
                            {aiIdentifiedConditions.find(condition => condition.highlight_text === highlightedText)?.rationale || ''}
                        </Typography>
                    </Box>
                )}
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => setAlertOpen(false)}
                >
                    <Alert onClose={() => setAlertOpen(false)} severity="warning" sx={{width: '100%'}}>
                        The chart is locked. No changes can be made.
                    </Alert>
                </Snackbar>
                <ProcessingTimeModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    onSubmit={handleModalSubmit}
                />
            </Box>
        </ThemeProvider>
    );
};

export default CompletedCodesBuilder;
