import React from 'react';
import { Box, Typography, Switch } from '@mui/material';

const StatusToggles = ({ chart, handleDeficiencyToggle, handleBlockedToggle, handleSubmittableToggle }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: 2,
                gap: 3,
                backgroundColor: '#f5f5f5',
                border: '1px solid #ccc',
                borderRadius: 2,
            }}
        >
            {/* Documentation Deficiency Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                    Out of Scope
                </Typography>
                <Switch
                    checked={chart?.documentation_deficiency}
                    onChange={handleDeficiencyToggle}
                />
            </Box>

            {/* Blocked Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                    Blocked
                </Typography>
                <Switch
                    checked={chart?.blocked}
                    onChange={handleBlockedToggle}
                />
            </Box>

            {/* Submittable Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                    Submittable
                </Typography>
                <Switch
                    checked={chart?.submittable}
                    onChange={handleSubmittableToggle}
                />
            </Box>
        </Box>
    );
};

export default StatusToggles;
