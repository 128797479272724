import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import { Person } from '@mui/icons-material';
import { selectToken } from "../Auth/selectors";
import { logout } from '../apis/auth';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../styles/theme';

function Header() {
    const token = useSelector(selectToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
        handleMenuClose();
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleSignup = () => {
        navigate('/signup');
    };

    const handleHome = () => {
        navigate('/');
    };

    const handleUsers = () => {
        navigate('/users');
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ cursor: 'pointer', fontStyle: 'bold'}}
                        onClick={handleHome}
                    >
                        HealthMC
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {token ? (
                        <>
                            <Button color="inherit" onClick={handleHome} sx={{ marginLeft: 3 }}>
                                Charts
                            </Button>
                            <Button color="inherit" onClick={handleUsers} sx={{ marginLeft: 3 }}>
                                Admin
                            </Button>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleMenuOpen}
                                sx={{ marginLeft: 3 }}
                            >
                                <Avatar>
                                    <Person />
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" onClick={handleLogin}>
                                Login
                            </Button>
                            <Button color="inherit" onClick={handleSignup}>
                                Sign up
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default Header;
